import React from 'react'

import * as S from './CashbackSteps.styles'
import cashbackStepStar from '../../assets/images/cashback-step-star.png'

const CashbackSteps = () => (
  <S.CashbackSteps>
    <S.StepConector />
    <S.Step>
      <S.StepNumber>1</S.StepNumber>
      <div>
        <strong>Selecione a loja</strong> que quiser
      </div>
    </S.Step>
    <S.Step>
      <S.StepNumber>2</S.StepNumber>
      <div>
        <strong>Clique em “Ir para a loja”</strong>
      </div>
    </S.Step>
    <S.Step>
      <S.StepNumber>3</S.StepNumber>
      <div>
        <strong>Finalize sua compra</strong> no site da loja
      </div>
    </S.Step>
    <S.Step>
      <S.StepStar src={cashbackStepStar} alt="Último passo para receber o cashback" />
      <div>
        <strong>Receba o cashback</strong> entre 45 e 120 dias, direto na sua conta.
      </div>
    </S.Step>
  </S.CashbackSteps>
)

export default CashbackSteps

import styled from 'styled-components'
import { Col } from 'react-bootstrap'

import { mediaQuery } from '../../../assets/styles/variables'

export const Home = styled.div`
  font-size: 1rem;
`

export const PageTitle = styled.h1`
  font-size: 1.25rem;
  font-weight: 600;
  font-family: Sora;
  color: var(--gray500);
  margin: 2.5rem 0 1rem;

  @media ${mediaQuery.minMD} {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  @media ${mediaQuery.minLG} {
    font-size: 2rem;
  }

  @media ${mediaQuery.minXL} {
    font-size: 2.5rem;
  }
`

export const Subtitle = styled.p`
  color: var(--gray400);
  margin-bottom: 0.75rem;
  font-size: 0.875rem;

  @media ${mediaQuery.minMD} {
    font-size: 1rem;
  }

  @media ${mediaQuery.minXL} {
    font-size: 1.125rem;
  }
`

export const DashedLine = styled.div`
  margin: 2.5rem 0;
  border-bottom: 1px dashed var(--gray200);
`

export const FeaturedStoresCol = styled(Col)`
  margin-top: 1.5rem;

  @media ${mediaQuery.minXL} {
    margin-top: 2.5rem;
  }
`

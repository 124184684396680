import styled from 'styled-components'

import { mediaQuery } from '../../assets/styles/variables'

export const CashbackSteps = styled.ul`
  list-style: none;
  margin-top: 1.5rem;
  position: relative;
  font-size: 0.875rem;

  @media ${mediaQuery.minMD} {
    margin-top: 3.5rem;
    font-size: 1rem;
  }
`

export const Step = styled.li`
  padding-bottom: 1.5rem;
  display: flex;
  align-items: center;
`

export const StepNumber = styled.span`
  background-color: black;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 0.75rem;
  color: white;
  margin-right: 1rem;
  font-weight: 600;
  z-index: 10;
  border: 2px solid #f5f6fa;
`

export const StepStar = styled.img`
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  z-index: 10;
  background-color: white;
`

export const StepConector = styled.div`
  position: absolute;
  width: 3px;
  height: 8rem;
  background-color: #f5f6fa;
  left: 0.625rem;
  top: 1rem;

  @media ${mediaQuery.minMD} {
    height: 10rem;
  }
`

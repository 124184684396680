import React from 'react'

import { useMediaQuery } from 'react-responsive'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import {
  BannersCarousel,
  BannersCarouselShimmer,
  FeaturedStores,
  FeaturedStoresShimmer,
  Faq,
} from '@interco/affiliate-shopping-ui'

import faqData from './faqData.json'
import { getBanners, getFeaturedItems } from '../../../api'
import useAsync from '../../../hooks/useAsync'
import BannerRes from '../../../types/BannerRes'
import FeaturedItemsRes from '../../../types/FeaturedItemsRes'
import Layout from '../../Layout'
import Seo from '../../Seo'
import { mediaQuery } from '../../../assets/styles/variables'
import * as S from './Home.styles'
import FaqItem from '../../../types/FaqItem'
import CashbackSteps from '../../CashbackSteps'
import HowItWorksCard from '../../HowItWorksCard'

const bannerDefaultHeight = '360px'

const Home = () => {
  const bannersReq = useAsync<BannerRes>()
  const featuredItemsReq = useAsync<FeaturedItemsRes>()

  const minMD = useMediaQuery({ query: mediaQuery.minMD })

  React.useEffect(() => {
    bannersReq.run(getBanners())
    featuredItemsReq.run(getFeaturedItems())
  }, [])

  const faqStructuredData = React.useMemo(
    () => ({
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity:
        faqData.length > 0
          ? faqData.map((faqItem: FaqItem) => ({
              '@type': 'Question',
              name: faqItem.question,
              acceptedAnswer: {
                '@type': 'Answer',
                text: faqItem.answer,
              },
            }))
          : null,
    }),
    [],
  )

  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(faqStructuredData)}</script>
      </Helmet>

      <Seo
        title="Lojas com cashback"
        description="Compre no site das suas lojas preferidas aqui e ganhe dinheiro de volta em todas as suas compras. Tem ofertas + cashback nas maiores e melhores lojas do Brasil!"
        canonical="/"
      />

      {bannersReq.isSuccess && (
        <BannersCarousel
          className="cy-first-banners-carousel"
          banners={
            minMD ? bannersReq.data?.banners1.desktop || [] : bannersReq.data?.banners1.mobile || []
          }
          showControlArrows={minMD}
          heightDefault={bannerDefaultHeight}
        />
      )}
      {(bannersReq.isIdle || bannersReq.isLoading) && (
        <BannersCarouselShimmer heightDefault={bannerDefaultHeight} />
      )}

      <Container>
        <Row>
          <Col xs={12} md={6} lg={7}>
            <S.PageTitle>Ganhe cashback comprando no site da loja</S.PageTitle>
            <S.Subtitle>
              Aqui você escolhe uma loja parceira onde quer comprar, finaliza seu pedido e{' '}
              <strong>ganha cashback direto na sua conta</strong>. É fácil, rápido, e você sai
              ganhando sempre!
            </S.Subtitle>
            <S.Subtitle>
              E tem mais: pagando com o cartão de crédito <strong>Meu Galo BMG</strong>, você ainda
              ganha cashback no pagamento da fatura! Você e o Galo saem ganhando!
            </S.Subtitle>
          </Col>

          <Col xs={12} md={6} lg={5} xl={{ span: 3, offset: 1 }}>
            <CashbackSteps />
          </Col>

          <S.FeaturedStoresCol xs={12}>
            {featuredItemsReq.isSuccess && (
              <FeaturedStores
                variant={minMD ? 'grid' : 'row'}
                linkTag={Link}
                className="first-feature-stores-grid"
                title={featuredItemsReq.data?.featuredStores1.title ?? ''}
                subtitle={featuredItemsReq.data?.featuredStores1.subtitle ?? ''}
                stores={featuredItemsReq.data?.featuredStores1.stores ?? []}
                storeCardLinkPrefix="/parceiro"
                storesButtonLink="/parceiros"
                buttonText="Ver todas as lojas"
              />
            )}
            {(bannersReq.isIdle || bannersReq.isLoading) && (
              <FeaturedStoresShimmer variant={minMD ? 'grid' : 'row'} />
            )}
          </S.FeaturedStoresCol>

          <Col xs={12}>
            <S.DashedLine />

            <HowItWorksCard />

            <S.DashedLine />

            <Faq
              title="Perguntas frequentes"
              description="Ainda tem dúvidas? Veja se podemos te ajudar com essas respostas."
              faqItems={faqData}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Home
